import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../layouts';

import { Footer, Header, Icon, Menu, BlogPosts, Pagination, SEO } from '../../components';

import * as S from './styles';

const BlogCategoryTemplate = ({ data, pageContext }) => {
  const category = {
    nodes: [data.wpCategory],
  };

  return (
    <Layout>
      <>
        <SEO title={data.wpCategory.name} description={data.wpCategory.description} />
        <Header />
        <Menu postCategories={category} />

        <S.Content>
          <S.CategoryInfo>
            <S.Category>
              <Icon name="icon-tag" />

              <S.Texts>
                <S.Title>{data.wpCategory.name}</S.Title>
                <S.Count>{data.wpCategory.count} Artigos</S.Count>
              </S.Texts>
            </S.Category>
            {data.wpCategory.description && <S.Description dangerouslySetInnerHTML={{ __html: data.wpCategory.description }} />}
          </S.CategoryInfo>

          <BlogPosts posts={data.allWpPost.edges} />

          {pageContext.numPages > 1 && (
            <Pagination currentPage={pageContext.currentPage} slug={data.wpCategory.slug} totalPages={pageContext.numPages} />
          )}
        </S.Content>
        <Footer />
      </>
    </Layout>
  );
};

export default BlogCategoryTemplate;
export const result = graphql`
  query($id: Int!, $skip: Int, $limit: Int) {
    wpCategory(databaseId: { eq: $id }) {
      count
      description
      name
      slug
      databaseId
    }
    allWpPost(filter: { categories: { nodes: { elemMatch: { databaseId: { eq: $id } } } } }, limit: $limit, skip: $skip) {
      edges {
        node {
          author {
            node {
              avatar {
                url
              }
              name
            }
          }
          date(formatString: "D [de] MMMM [de] YYYY", locale: "pt-br")
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxHeight: 310, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    src
                  }
                }
              }
              title
            }
          }
          slug
          title
          databaseId
        }
      }
    }
  }
`;
