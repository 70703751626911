import styled from 'styled-components';
import { media } from '../../styles/media-queries';

export const Content = styled.main`
  margin: 0 auto;
  max-width: ${(props) => props.theme.grid.container};
  width: 100%;
`;

export const CategoryInfo = styled.section`
  ${media.mobile`
    margin: 30px auto;
  `}

  ${media.tablet`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 30px auto;
    padding: 0 15px;
  `}

  ${media.desktop`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 60px auto;
    width: 712px;
  `}
`;

export const Category = styled.div`
  align-items: center;
  display: flex;
  margin-right: 60px;

  > svg {
    display: block;
    height: 54px;
    margin-right: 20px;
    transform: rotate(-45deg);
    width: 54px;
  }

  ${media.mobile`
    justify-content: center;
    margin: 0 0 30px;
  `}
`;

export const Texts = styled.div``;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Count = styled.span`
  color: #596172;
  display: block;
  font-size: 18px;
  font-weight: 700;

  &:before {
    background-color: #596172;
    border-radius: 100%;
    content: '';
    display: inline-block;
    height: 10px;
    margin-right: 8px;
    width: 10px;
  }
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 25px;

  ${media.mobile`
    padding: 0 15px;
  `}
`;
